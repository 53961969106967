import request from '@/utils/request'

// 列出所有节日提醒
export function findFestivalReminds(params) {
  return request({
    url: `/festival_reminds`,
    method: 'get',
    params: params
  })
}
// 更新节日提醒状态
export function updateFestivalRemindEffective(id, data) {
  return request({
    url: `/festival_reminds/${id}/effective`,
    method: 'patch',
    data
  })
}
// 更新节日提醒信息
export function updateFestivalRemind(id, data) {
  return request({
    url: `/festival_reminds/${id}`,
    method: 'put',
    data
  })
}
// 创建节日提醒
export function createFestivalRemind(data) {
  return request({
    url: `/festival_reminds`,
    method: 'post',
    data
  })
}
// 删除节日提醒
export function deleteFestivalRemind(id) {
  return request({
    url: `/festival_reminds/${id}`,
    method: 'delete'
  })
}
